@import "../assets/scss/common.scss";

.container {
    // border: 1px solid red;
    font-family: $amanin_font_family;
    margin: 5px;
    padding: 0px 15px 15px 15px;

    &_title {
        display: flex;
        justify-content: center;
        font-size: 1.2rem;
        font-weight: 500;
        color: #555555;
        margin-top: 10px;
    }

    &_title2 {
        color: #555555;

        &>div {
            text-align: center;
            font-size: 1.2rem;
        }

        &>span {
            position: absolute;
            border-radius: 5px 0px 5px 0px;
            padding-left: 5px;
            padding-right: 5px;
            transition: ease .2s;
            font-size: .8rem;

            &:hover {
                cursor: pointer;
                color: white;
                background-color: rgb(26, 73, 46);
            }
        }
    }

    &_login {
        & .split {
            border: 2px solid black;
            display: flex;
            border: 1px solid red;
            color: black;

            &>div {}
        }

        &_c {
            display: flex;
            flex-direction: column;

            & .title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 25px;
                margin-bottom: 2px;
                color: #555555;

                min-height: 26px;

                & span {
                    font-size: .9rem;
                    font-weight: 400;
                }

                & a {
                    text-decoration: none;
                    font-size: .8rem;
                    font-weight: 400;
                    color: #5aba37;

                    &:hover {
                        color: #00982b;
                    }
                }

                &>img {

                    // border:1px solid red;
                    &:hover {
                        cursor: pointer;
                        background-color: #00982b3a;
                        border-radius: 3px;
                        transition: .5s;
                    }
                }
            }

            & input[type=text],
            input[type=password] {
                font-size: .9rem;
                padding: 13px;
                color: #636262;
                border-radius: 5px;
                border: 1px solid rgba(0, 0, 0, 0.4);
                box-sizing: border-box;
                // border: 3px solid #ccc;
                -webkit-transition: 0.2s;
                transition: 0.5s;
                outline: none;

                &:focus {
                    border: 2px solid rgba(16, 172, 50, 0.598);
                }
            }

            & .btnLogin {
                display: flex;
                border-radius: 50px;
                margin: 30px 0 10px 0;
                padding: 10px;
                font-size: .9rem;
                font-weight: 330;
                justify-content: center;
                align-items: center;
                -webkit-transition: 0.2s;
                transition: 0.2s;
            }
        }

        &_signup {
            display: flex;
            justify-content: space-between;
            color: rgb(36, 94, 56);
            text-align: end;
            font-size: .9rem;
            margin-top: 3px;

            &>span {
                border-radius: 5px 0px 5px 0px;
                padding-left: 5px;
                padding-right: 5px;
                padding-bottom: 3px;
                transition: ease .2s;

                &:hover {
                    cursor: pointer;
                    color: white;
                    background-color: rgb(26, 73, 46);
                }
            }
        }
    }

    &_sep {
        display: flex;
        align-items: center;

        // border:1px solid red;
        &>div {
            flex-grow: 1;
            border: 1px solid #7878785a;
            border-bottom: 0px;
            margin-top: 25px;
            margin-bottom: 25px;
        }

        &>span {
            margin-left: 10px;
            margin-right: 10px;
            color: #787878;
            // border:1px solid red;
            // padding-bottom: 15px;
        }
    }

    &_oauth {
        &>div {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: .9rem;
            font-weight: 330;
            border-radius: 50px;
            padding: 10px;
            margin-bottom: 10px;
            transition: 0.5s;
        }
    }

    &_close {
        display: flex;
        justify-content: center;

        &>div {
            font-size: .8rem;
            font-weight: 330;
            padding: 10px;
            transition: 0.5s;
        }
    }
}